import React from 'react'
import parse from 'html-react-parser'
import { Top10DataList } from '../components/hooks/top10data'

const RedirectList = props => {
  const { edges } = Top10DataList()
  let redrectlist = []
  //console.log('edges=', edges)
  //console.log("Top10Array=",Top10Array);

  return (
    <React.Fragment key={'2222'}>
      {edges.map((node, index) => {
        const top10record = node.node
        //console.log('top10record=', top10record)

        let companyname = top10record.companyname.toLowerCase()
        const removeStr = ' ' //variable
        const regex = new RegExp(removeStr, 'g') // correct way
        companyname = companyname.replace(regex, '-') // it works
        const reviewPath = '/' + companyname + '-review'

        const external_url = top10record.launchurl

        var res = external_url.replace('<a href="', '')
        // .replace("\"","")

        res = res.substring(0, res.indexOf('"'))
        const removeStr2 = '"' //variable
        const regex2 = new RegExp(removeStr2, 'g') // correct way
        res = res.replace(regex2, '') // it works

        ///visit-web-host.aspx/siteground.com/135
        const fullredirstr_visit_host =
          '/visit-web-host.aspx/' +
          companyname +
          '/' +
          top10record.top10id +
          '  ' +
          res +
          ' 302 ' //+ ' ' + external_url

        const companyid_visit_host =
          '/visit-web-host.aspx/' +
          companyname +
          '/' +
          top10record.companyid +
          '  ' +
          res +
          ' 302 ' //+ ' ' + external_url


        const fullredirstr_review =
          '/ResearchHostingCompany.aspx/' +
          companyname +
          '/' +
          top10record.companyid +
          '  ' +
          reviewPath +
          ' 301 ' //+ ' ' + external_url

          redrectlist.push(companyid_visit_host)
        //redrectlist.push(fullredirstr_visit_host)
        //redrectlist.push(fullredirstr_review)

        //https://www.findbestwebhosting.com/ResearchHostingCompany.aspx/siteground.com/424

        //   format: /portfolio  /aboutus 301
        //https://www.findbestwebhosting.com/ResearchHostingCompany.aspx/siteground.com/424

        return ''
      })}

      {
        <React.Fragment key={'44444'}>
          {parse(redrectlist.filter(function (item, pos) {
            return redrectlist.indexOf(item) === pos
          }).join("<br/>"))}
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default RedirectList
